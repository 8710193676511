import React from 'react';
import {Header, DetailLayout, Card, OnLine} from '../../components';
import {ThemeTable, ThemeTicker} from '../../widgets'
import { FinancialTicker, FinancialWatchlist} from 'fs-widgets-core';
import { currentTheme } from '../../theme/hooks/themeSlice';
import { useAppSelector } from '../../app/hooks';
import { createTheme } from '@mui/material';

export function Layout2() {
    const theme = useAppSelector(currentTheme);

    return (
        <>
            <Header/>
            <DetailLayout>
                <section className="widget-ticker">
                    <ThemeTicker className="small">
                        <FinancialTicker
                            key={`main-FinancialTicker-${36}`}
                            instance={'36'}
                            language={'es_MX'} 
                        />
                    </ThemeTicker>
                </section>
                <Card titleCard='Información de Fondos Principal y Acciones' className='detail-watchlist'>
                    <ThemeTable contenWatchlist>
                        <FinancialWatchlist
                            headerFixed={true}
                            instance='45'
                            language={'es_MX'}
                            theme={createTheme({
                                palette: {
                                    background: {
                                        paper: '#001865',
                                    }
                                },
                            })}
                            headerHeight={32}
                            rowHeight={40}
                            key={'watchlist-page-8'}
                            customNumberMatcher
                            currentTheme={theme}
                            labelAddTab='Lista'
                            textMaxQuotes='Máx'
                            textMinQuotes='Mín'
                            textPrice='$'
                            textValue='Var.'
                        />
                    </ThemeTable>
                </Card>
            </DetailLayout>
        </>
    )
    
}