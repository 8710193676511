const Colors = {
    primary: '#0076CF',
    secondary: '#004C97',
    tertiary: '#00142B',
    quaternary: '#003865',
    chart: '#0098DD',
    error: '#C00000',
    success: '#00A29B',
    warining: '#F2994A',
    info: '#0098DD',
    neutral1: '#315470',
    neutral2: '#353535',
    neutral3: '#BDBDBD',
    neutral4: '#D9D9D9',
    neutral5: '#F2F6FA', // row table and chart
    up1: '#3FBDB8',
    up2: '#00A29B',
    up3: '#006561',
    down1: '#D93131',
    down2: '#C00000',
    down3: '#850000',
    white: '#FFFFFF',
    black: '#000000',
    light: '#F2F6FA',
    text: '#333333',
    text2: '#004C97',
    hover: 'rgba(0,0,0,0.05)', 
    logoText: '#4E4F53',

    
    errorDark: '#EB5757',
    successDark: '#1BC47D',
    up1Dark: '#1BC47D',
    up2Dark: '#179962',
    up3Dark: '#0D7D4E',
    down1Dark: '#EB5757',
    down2Dark: '#C73C3C',
    down3Dark: '#A12C2C',
    dark: '#000912',
    textDark: '#F6F6F8',
    text2Dark: '#F6F6F8',
    hoverDark: 'rgba(255,255,255,0.05)', 
    neutral1Dark: '#315470',
    neutral2Dark: '#001932', // bgModal
    neutral3Dark: '#1C2432', // btn disable scroll
    neutral4Dark: '#9D9EA4', // dividers, border
    neutral5Dark: 'rgba(0,0,0,0.2)',  // row table and chart
};
  
  export default Colors;