import React, { useState } from 'react';
import { AtomicTabs, AtomicTabPanel, AtomicText } from '../../atom';
import { StyleTab } from '../../atom/Tabs/style';
import { Header, AppLayout, Card, OnLine } from '../../components';
import { ThemeTicker, ThemeTable, ThemeChart } from '../../widgets'
import { FinancialTicker, FinancialTable, FinancialWinnersLosers, FinancialAdvancedChart } from 'fs-widgets-core';
import FlagMX from '../../assets/images/flag_xxx.png';
import FlagUSA from '../../assets/images/flag_usd.png';
import FlagDefault from '../../assets/images/flag_default.png';
import { currentTheme } from '../../theme/hooks/themeSlice';
import { useAppSelector } from '../../app/hooks';

export function Layout(props: {
    tabsComponent: any[];
}) {
    const theme = useAppSelector(currentTheme);
    const {
        tabsComponent,
    } = props;
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const changeTab = (index: number) => {
    };

    const data = [
        {
            country: 'default',
            url: FlagDefault
        },
        {
            country: 'MEX',
            url: FlagMX
        },
        {
            country: 'USA',
            url: FlagUSA
        },
    ]

    return (
        <>
            <Header />
            <AppLayout>
                <section className="widget-ticker">
                    <ThemeTicker className="small">
                        <FinancialTicker
                            key={`main-FinancialTicker-${36}`}
                            instance={'36'}
                            language={'es_MX'}
                        />
                    </ThemeTicker>
                </section>
                <Card titleCard='Índices' className='indices'>
                    <ThemeTable>
                        <FinancialTable
                            key={`main-FinancialTable-${40}`}
                            instance={'40'}
                            language={'es_MX'}
                            countryFlags={data}
                            headerHeight={40}
                            rowHeight={40}
                            suppressMovable={true}
                        />
                    </ThemeTable>
                </Card>
                <Card titleCard='Monedas' className='monedas'>
                    <ThemeTable>
                        <FinancialTable
                            key={`main-FinancialTable-${39}`}
                            instance={'39'}
                            language={'es_MX'}
                            countryFlags={data}
                            headerHeight={40}
                            rowHeight={40}
                            suppressMovable={true}
                        />
                    </ThemeTable>
                </Card>
                <Card titleCard='Commodities' className='commodities'>
                    <ThemeTable>
                        <FinancialTable
                            key={`main-FinancialTable-${38}`}
                            instance={'38'}
                            language={'es_MX'}
                            countryFlags={data}
                            headerHeight={40}
                            rowHeight={40}
                            suppressMovable={true}
                        />
                    </ThemeTable>
                </Card>
                <Card titleCard='Gráfico' className='grafica'>
                    <ThemeChart>
                        <FinancialAdvancedChart
                            key={`main-FinancialSimpleChart-${6}`}
                            instance={'46'}
                            language={'es_MX'}
                            theme={theme}
                        />
                    </ThemeChart>
                </Card>

                <section className="flex-content">
                    <Card titleCard='Mayores Alzas y Mayores Bajas' className='wl'>
                        <ThemeTable>
                            <AtomicTabs
                                value={value}
                                onChange={(e: any, val: any) => handleChange(e, val)}
                            >
                                {tabsComponent!.map((item, index) => (
                                    <StyleTab
                                        label={item.label}
                                        value={item.value}
                                        icon={item.icon}
                                        iconPosition="end"
                                        onClick={() => changeTab(index)}
                                    />
                                ))}
                            </AtomicTabs>
                            {
                                tabsComponent!.map(
                                    (item, index) => (
                                        <AtomicTabPanel key={`tab-panel-${index}`} value={value} index={index}>
                                            <FinancialWinnersLosers
                                                key={`main-FinancialWinnersLosers-${item.instance}`}
                                                instance={item.instance}
                                                language={'es_MX'}
                                                removeWidgetFromDashboard={false}
                                                theme={theme}
                                                winnersName='Mayores Alzas'
                                                losersName='Mayores Bajas'
                                            />
                                        </AtomicTabPanel>
                                    )
                                )
                            }
                        </ThemeTable>
                    </Card>
                    <Card titleCard='Más Transadas' className='transadas'>
                        <ThemeTable>
                            <FinancialWinnersLosers
                                key={`main-FinancialWinnersLosers-37`}
                                instance={'37'}
                                language={'es_MX'}
                                removeWidgetFromDashboard={false}
                                theme={theme}
                                winnersName='Nombre'
                                losersName='Nombre'
                            />
                        </ThemeTable>
                    </Card>
                    <Card titleCard='Tasas' className='tasas'>
                        <ThemeTable>
                            <FinancialTable
                                key={`main-FinancialTable-${41}`}
                                instance={'41'}
                                language={'es_MX'}
                                countryFlags={data}
                                headerHeight={30}
                                rowHeight={40}
                                suppressMovable={true}
                            />
                        </ThemeTable>
                    </Card>
                    <Card titleCard='Fondos Mutuos: Renta Variable' className='fondos-rv height-table' showLogo>
                        <ThemeTable>
                            <FinancialTable
                                key={`main-FinancialTable-${42}`}
                                instance={'42'}
                                language={'es_MX'}
                                countryFlags={data}
                                headerHeight={30}
                                rowHeight={40}
                                suppressMovable={true}
                            />
                        </ThemeTable>
                    </Card>
                    <Card titleCard='Fondos Mutuos: Deuda' className='fondos-d height-table' showLogo>
                        <ThemeTable>
                            <FinancialTable
                                key={`main-FinancialTable-${43}`}
                                instance={'43'}
                                language={'es_MX'}
                                countryFlags={data}
                                headerHeight={30}
                                rowHeight={40}
                                suppressMovable={true}
                            />
                        </ThemeTable>
                    </Card>
                    <Card titleCard='Fondos Mutuos: Balanceados' className='fondos-b height-table' showLogo>
                        <ThemeTable>
                            <FinancialTable
                                key={`main-FinancialTable-${44}`}
                                instance={'44'}
                                language={'es_MX'}
                                countryFlags={data}
                                headerHeight={30}
                                rowHeight={40}
                                suppressMovable={true}
                            />
                        </ThemeTable>
                    </Card>
                    <Card className='ifram'>
                        <iframe
                            height="100%"
                            width="100%"
                            frameBorder={0}
                            src='https://dev-principal-cl-drupal-public-site.pantheonsite.io/sites/default/files/prueba-ro/serie-digital/prueba.html'
                            scrolling="no"
                            loading="lazy"
                        />
                    </Card>
                </section>
            </AppLayout>
        </>
    )

}