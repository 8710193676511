import styled, {keyframes} from 'styled-components';
import { FontSize } from '../../utils/Constants/FontSize';

const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

const WrapperCard = styled.a <any>`
    box-sizing: border-box;
    width: 120px;
    height: 100%;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding-left: 5px;
    text-decoration: none;
    color: ${props => props.theme.palette.layout.text}; 
    &:hover{
        text-decoration: underline;
        color: ${props => props.theme.palette.colors.primary}; 
    }
    p{
        font-family: ${props => props.theme.typography.primary}, sans-serif !important;
        ${FontSize[9]};
        font-weight: 400;
        margin: 0;
    }
    @media only screen and (max-width: 599px){
        width: 70px;
    }
`;
const Light = styled.div <any>`
    min-width: 7px;
    height: 7px;
    border-radius: 4px;
    animation: ${blinkAnimation} 1s linear infinite;
    &.success{
        background: ${props => props.theme.palette.colors.success};
    }
    &.error{
        background: ${props => props.theme.palette.colors.error};
    }
`;

export { WrapperCard, Light };