import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const Btn = styled.a <any>`
  position: relative;
  border-radius: 0;
  box-shadow: none;
  color: ${props => props.theme.palette?.header.text};
  padding: 0 8px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  height: 36px;
  ${FontSize[12]};
  font-family: ${props => props.theme.typography.primary};
  background: transparent;
  border: none;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  transition: all .2s;
  &::after{
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: rgba(255,255,255,.5);
    transition: width .5s;
  }
  &:hover{
    &::after{
      width: 100%;
    }
  }
  &.active{
    font-weight: 700;
    &::after{
      width: 100%;
      background: ${props => props.theme.palette?.header.text};
    }
  }
`;

export { Btn };