import React from "react";
import { Layout } from '../pages/layout/layout';
import { Layout2 } from '../pages/layout2/layout';
import { Ticker } from '../pages/ticker/ticker';
import { AuthProvider } from "../contexts/AuthContext";
import { Table, BarChart } from 'react-feather';

export const routes = [
    {
        path: '/layout-one',
        component: <Layout 
                        tabsComponent={[
                            {
                                label: 'Vista Tabla',
                                value: 0,
                                icon: <Table/>,
                                instance: '21'
                            },
                            {
                                label: 'Vista Gráfica',
                                value: 1,
                                icon: <BarChart/>,
                                instance: '5'
                            }
                        ]}
                    />
    },
    {
        path: '/layout-two',
        component: <Layout2 />
    },
    {
        path: '/ticker/:theme',
        component: <Ticker />
    },
]



