import styled from "styled-components";

const ContentMenu = styled.nav`
    display: flex;
    button + button{
      margin-left: 1rem;
    }
    a + a{
      margin-left: 1rem;
    }
  
`;

export { ContentMenu };
