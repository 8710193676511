import React, {type FC} from 'react';
import Box from '@mui/material/Box';
import './style.css'
import TabPropsProperties from './interface'

const AtomicTabPanel: FC<TabPropsProperties> = (props) => {
  const { 
    children, 
    value, 
    index, 
    heightContentAuto,
    ...other 
} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      className={heightContentAuto ? 'autoHeight' : ''}
    >
      {value === index && (
        <Box pt={1} className={heightContentAuto ? 'fullHeight' : ''}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default AtomicTabPanel;