import styled from "styled-components";
import { FontSize } from "../../utils/Constants/FontSize";

const HeaderStyle = styled.header`
  position: sticky;
  top: 0;
  z-index:10;
  background: ${(props) => props.theme.palette.layout.bg};
  background: linear-gradient(10deg, rgba(0, 76, 151, 1) 23.89%, rgba(0, 145, 218, 1) 86.77%);
  background-origin: top;
  background-size: 100% 100%;
  color: ${props => props.theme.palette.header.text};
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  .content-left{
    display: flex;
    gap: 2rem;
    align-items: center;
    @media only screen and (max-width: 700px){
      gap: 1rem;
    }
    .logo {
      margin-left: 1rem;
    }
    .line{
      background-color: ${props => props.theme.palette.header.text};
      width: 1px;
      height: 28px;
      display: inline-block;
      @media only screen and (max-width: 420px){
        display: none;
      }
    }
    h1 {
      ${FontSize[20]};
      line-height: 1;
      font-family: ${props => props.theme.typography.primary}, sans-serif;
      font-weight: 700;
      
      @media only screen and (max-width: 700px){
        ${FontSize[16]};
      }
      @media only screen and (max-width: 420px){
        display: none;
      }
    }
  }
  .content-right{
    display: flex;
    padding-right: 1rem;
    gap: 1rem;
    align-items: center;
    nav{
      button + button{
        margin-left: 1rem;
      }
    }
  }
  
  
`;

const HeaderMenu = styled.div`
  background: ${(props) => props.theme.palette.layout.bg};
  background: linear-gradient(10deg, rgba(0, 76, 151, 1) 23.89%, rgba(0, 145, 218, 1) 86.77%);
  background-origin: top;
  background-size: 100% 100%;
  color: ${props => props.theme.palette.header.text};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  .content-left{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 10px 0;
    .logo {
      margin: 0;
    }
    .line{
      background-color: ${props => props.theme.palette.header.text};
      width: 1px;
      height: 24px;
      display: inline-block;
    }
    h1 {
      ${FontSize[18]};
      line-height: 1;
      font-family: ${props => props.theme.typography.primary}, sans-serif;
      font-weight: 700;
    }
  }
  /* code for menu vertical */
  /* nav{
    flex-direction: column;
    width: 100%;
    button{
      width: 100%;
    }
    button + button{
      margin-left: 0;
    }
  } */
`;

export { HeaderStyle, HeaderMenu };
