import { type FC } from 'react'
import { Layout } from "./style"
import AppLayoutPropsInterface from "./interface"
import { useAppSelector } from '../../../app/hooks';
import { currentTheme } from '../../../theme/hooks/themeSlice';
import MessageFooter from '../../messageFooter'

const AppLayout: FC<AppLayoutPropsInterface> = (props) => {
    const theme = useAppSelector(currentTheme);
    const {
        className
    } = props
    return (
        <Layout theme={theme} className={className}>
            {props.children}
            <MessageFooter className="footer"/>
        </Layout>
    )
}

export default AppLayout;
