import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentTwitter = styled.div`
    > div{
        background-color: transparent !important;
    }
    .wrapper-twitter{
        background-color: transparent !important;
    }
    .noticeCard{
        color: ${props => props.theme.palette.layout.text};
        .header{
            ${FontSize[11]};
            .name{
                color: ${props => props.theme.palette.layout.text} !important;
                font-weight: 700;
            }
        }
        .description{
            ${FontSize[10]};
            font-weight: 400;
            a{
                color: ${props => props.theme.palette.colors.secondary} !important;
            }
        }
    }
`;

export { ContentTwitter };