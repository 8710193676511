import React, { useState } from "react"
import { HeaderStyle, HeaderMenu } from "./style"
import { changeTheme } from "../../theme/hooks/themeSlice";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';
import Logo from '../../assets/images/logo.svg'
import { AtomicButtonIcon, AtomicTooltip } from "../../atom";
import { Search, Sun, Moon, Menu } from 'react-feather';
import MenuItems from '../menu'
import Hidden from '@mui/material/Hidden';
import { Drawer } from "@mui/material";
import { FinancialSearch } from "fs-widgets-core";


const ContentLeft = () => (
    <div className="content-left">
        <img
            className="logo"
            src={Logo}
            alt={"Logo Principal"}
            width='112.36px'
            height='31.82px'
            loading="lazy"
        />
        <div className="line"></div>
        <h1>Global Markets</h1>
    </div>
)

const Header = () => {
    const dispatch = useAppDispatch();
    const theme = useAppSelector(currentTheme);
    const [isDark, setIsDark] = useState(true);
    const [open, setOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
  
    const handleDrawerOpen = () => {
      setOpen(true);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const selectedTheme = isDark?'ligth':'dark'
    const handleTheme = (color: 'dark'|'ligth') => () => {
        setIsDark(!isDark)
        dispatch(changeTheme(color));
    }

    const menuIconBtn = [
        
        {
            tooltip: "Tema",
            class: "header-color-svg",
            action: handleTheme(selectedTheme),
            icon: isDark ? <Moon/> : <Sun />,
        },
    ]
    return (
        <HeaderStyle theme={theme}>
            <ContentLeft/>
            <div className="content-right">
                <Hidden smDown>
                    <MenuItems/>
                </Hidden>
                {menuIconBtn.map((item: any, index) => (
                    <AtomicTooltip title={item.tooltip} key={index}>
                        <AtomicButtonIcon
                            className={item.class}
                            onClick={item.action}
                        >
                            {item.icon}
                        </AtomicButtonIcon>
                    </AtomicTooltip>
                ))}
                <Hidden smUp>
                    <AtomicButtonIcon
                        className={'header-color-svg'}
                        onClick={handleDrawerOpen}
                    >
                        <Menu/>
                    </AtomicButtonIcon>
                </Hidden>
                <Drawer
                    anchor="top"
                    open={open}
                    onClose={handleDrawerClose}
                >
                    <HeaderMenu theme={theme}>
                        <ContentLeft/>
                        <MenuItems/>
                    </HeaderMenu>
                </Drawer>
            </div>
            
            <FinancialSearch
                language={'pt_BR'}
                open={openSearch}
                setOpenSearch={setOpenSearch}
                watchlistInstance={'8'}
            />
        </HeaderStyle>
    )

}

export default Header;

