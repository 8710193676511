import styled from "styled-components";
import { FontSize } from "../../utils/Constants/FontSize";

const ContentChart = styled.div<any>`
    height: ${props => props.height ? props.height : '100%'} !important;
    .headerChart{
        padding: 0 !important;
    }
    >div{
        background-color: transparent !important;
        .simple-chart, .advanced-chart{
            > div:last-child{
                max-height: 100% !important;
            }
            .contentData{
                color: ${props => props.theme.palette.layout.text} !important;
                .left{
                    .contentName{
                        font-family: ${props => props.theme.typography.primary} !important;
                        color: ${props => props.theme.palette.layout.text} !important;
                        .tickerName{
                            ${FontSize[14]};
                            font-family: ${props => props.theme.typography.primary} !important;
                        }
                    }
                    .date{
                        ${FontSize[10]};
                        font-family: ${props => props.theme.typography.secondary} !important;
                    }
                    
                    .contentPrices{
                        .price{
                            font-family: ${props => props.theme.typography.secondary} !important;
                            ${FontSize[26]};
                            svg{
                                margin-right: .5vw;
                                transform: scale(1.5)
                            }
                        }
                        .var, .percent{
                            font-family: ${props => props.theme.typography.secondary} !important;
                            ${FontSize[18]};
                        }
                    }
                    
                }
            }
        }
        g{
            &.cursor-line-stroke {
                stroke: ${props => props.theme.palette.layout.text} 
            }
            
            &.column-role {
                fill: ${props => props.theme.palette.chart.bg} 
            }
            
            &[role="slider"] g {
                fill: ${props => props.theme.palette.chart.slider} 
            }
        }
        text {
            fill: ${props => props.theme.palette.chart.text};
            font-family: ${props => props.theme.typography.secondary} !important;
            font-weight: 800;
        }
        .advanced-chart{
            &[color*="up"]{
                .contentName svg{
                    color: ${props => props.theme.palette.colors.success}; 
                }
                .var, .percent{
                    color: ${props => props.theme.palette.colors.success} !important; 
                }
            }
            &[color*="down"]{
                .contentName svg{
                    color: ${props => props.theme.palette.colors.error}; 
                }
                .var, .percent{
                    color: ${props => props.theme.palette.colors.error} !important; 
                }
            }
            .contentData{
                .right{
                    .btn-group{
                        > div{
                            > div[class*="control"]{
                                border-color: ${props => props.theme.palette.line.gray50} !important;
                            }
                        }
                        border-color: ${props => props.theme.palette.line.gray50};
                        button{
                            border-color: ${props => props.theme.palette.line.gray50};
                            color: ${props => props.theme.palette.layout.text};
                            svg{
                                color: ${props => props.theme.palette.layout.text};
                                fill: ${props => props.theme.palette.layout.text};
                            }
                            &:hover, &.active{
                                background: ${props => props.theme.palette.chart.bg};
                            }
                        }
                        @media (max-width: 1499px) {
                            >div{
                                display: none;
                            }
                            .icon-feather{
                                display: inline-flex !important;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export { ContentChart };