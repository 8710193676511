import React from "react"
import { ContentMenu } from "./style"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';
import { AtomicButtonHeader } from "../../atom";
import { useLocation, useNavigate } from "react-router";

const Menu = () => {
    const theme = useAppSelector(currentTheme);
    const location = useLocation();
    const navigate = useNavigate();
    const menuItems = [
        {
            href: "/layout-one",
            class: "",
            text: "Vista general",
        },
        {
            href: "/layout-two",
            text: "Vista detalle",
            class: "",
        },
    ]
    return (
        <ContentMenu theme={theme}>
            {menuItems.map((item, index) => (
                <AtomicButtonHeader
                    key={index}
                    onClick={() => navigate(item.href)}
                    text={item.text}
                    className={item.href === location.pathname ? 'active' : ''}
                />
            ))}
        </ContentMenu>
    )

}

export default Menu;

