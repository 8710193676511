import styled from "styled-components";

const ContentNotice = styled.div`
    height: calc(100%);
    > div{
        background-color: transparent !important;
    }
    /* TABS */
    .wrapper-notice{
        background-color: transparent !important;
    }
    .noticeCardDetail{
        background-color: ${props => props.theme.palette.layout.bg} !important;
        color: ${props => props.theme.palette.layout.text} !important;
        font-family: 'Proxima Nova' !important;
        button[class*="colorSecondary"]{
            background-color: ${props => props.theme.palette.colors.primary} !important;
            width: 2.3vw !important;
            height: 2.3vw !important;
            svg{
                width: 1.4vw !important;
                height: 1.4vw !important;
            }
        }
        .contentDate{
            background-color: transparent;
        }
        .title{
            color: ${props => props.theme.palette.colors.primary} !important;
            font-size: 1.3vw !important;
            padding-right: 10px;
        }
        .flexDate{
            svg{
                width: 1.1vw;
                height: 1.1vw;
            }
        }
        .date{
            font-family: 'Proxima Nova' !important;
            font-size: 1.1vw !important;
        }
        .description{
            font-size: 1.2vw !important;
            padding-right: 10px;
            line-height: 2;
            a{
                color: ${props => props.theme.palette.colors.secondary} !important;
            }
        }
    }
    .autoHeight{
        height: 100% !important;
        .fullHeight{
            height: 100%;
        }
    }
    .contentTab{
        justify-content: space-between;
        padding: 7px 0 10px 0 !important;
        gap: 0;
        .noticeCard{
            padding: 10px;
            color: ${props => props.theme.palette.layout.text};
            font-family: 'Proxima Nova' !important;
            &:hover{
                background-color: ${props => props.theme.palette.layout.bg};
            }
            .contentDate{
                svg{
                    width: 1.1vw;
                    height: 1.1vw;
                }
                .date{
                    font-size: 1.1vw !important;
                    font-family: 'Proxima Nova' !important;
                }
            }
            .title{
                color: ${props => props.theme.palette.layout.text} !important;
                font-size: 1.78vw !important;
            }
            .description{
                display: none;
                font-size: 1.65vw !important;
                -webkit-line-clamp: 3;
                a{
                    color: ${props => props.theme.palette.colors.secondary} !important;
                }
            }
        }
    }
`;

export { ContentNotice };