import styled from "styled-components";

const ContentQuote = styled.div`
    height: calc(100%);
    .wrapper-quote-chart{
        font-family: 'Proxima Nova' !important;
        gap: 0.5vw !important;
        .item-quote{
            background: #243137;
            background: linear-gradient(35deg, #243137 0%, #607d8b 100%);
            .info{
                padding: 10px;
                label{
                    color: white;
                    &.name{
                        font-size: 2vw;
                        margin-bottom: 0.3vw;
                        color: rgba(255,255,255,1);
                    }
                    &.price{
                    font-size: 2.4vw;
                    }
                    &.pct{
                    font-size: 1.5vw;
                    }
                }
            }
            .quote-chart{
            }
            &.up{
                background: #13A15D;
                background: linear-gradient(35deg, #13A15D 0%, #17ED86 100%);
            }
            &.down{
                background: #B52A2A;
                background: linear-gradient(35deg, #B52A2A 0%, #ff4747  100%);
            }
        }
    }
`;

export { ContentQuote };