import styled from 'styled-components';
import { FontSize } from '../../utils/Constants/FontSize';

const WrapperCard = styled.article <any>`
    width: 100%;
    background: ${props => props.theme.palette.card.bg};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.palette.layout.logo}; 
    .header-card{
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: ${props => `1px solid ${props.theme.palette.card.header}`};
        padding: 0 10px;
        min-height: 39px;
        h2,h3{
            font-family: ${props => props.theme.typography.primary}, sans-serif !important;
            color: ${props => props.theme.palette.card.text}; 
            line-height: 1.1;
            ${FontSize[14]};
            font-weight: 400;
            margin: 0;
        }
    }
    .body{
        flex-grow: 1;
        padding: 10px;
    }
`;

export { WrapperCard };