import React, { createContext, useEffect, useReducer } from "react";
import createActions from "./actions";
import reducer, { initialState } from "./reducer";
import { useNavigate } from "react-router-dom";
import { useRealtime } from "fs-widgets-core"
import { httpClient } from "fs-http-core"

import axios from "axios";

const AuthContext = createContext({
  ...initialState,
  user: null,
  dispatch: () => void 0,
  init: (isAuthenticated: boolean, user: any) => Promise.resolve(),
  register: (user: any, isAuthenticated: boolean, accessToken: any) => Promise.resolve(),
});

export const AuthProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = createActions(dispatch, state);
  const { configureAxiosInstance } = useRealtime();
  const navigate = useNavigate();
  const value = React.useMemo(
    () => ({
      ...state,
      ...actions
    }), [state, actions]
  );

  useEffect(() => {
    (async () => {
      try {
        if (state.user === null || !state.isInitialized) {
          await register();
        }
      } catch (err) {

      }
    })();

  }, [state.user]);

  const register = async () => {
    const userId = 'guest'
    const payload = {
      email: `${userId}@fswidgets.com`,
      firstName: userId,
      lastName: userId,
      userId: userId
    }

    if (userId) {
      try {
        dispatch({ type: "INIT", payload: { isInitialized: true } });
        const response = await axios.post(`${process.env.REACT_APP_KEYCLOAK_SERVICES_URL}/rest/api/auth/register`, payload)
        if (response.data && response.data.entity && response.data.entity.access_token) {

          const accessToken = response.data.entity.access_token
          const refreshToken = response.data.entity.refresh_token;
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('refresh_token', refreshToken);
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

          configureAxiosInstance({
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
          })

          const user = await httpClient.get(`${process.env.REACT_APP_CONFIG_SERVICES}/api/account`)
          dispatch({ type: "REGISTER", payload: { user, isAuthenticated: true, accessToken } });
        } else {
          navigate(`/forbidden`);
        }
      } catch (err) {

      }
    }

  };

  if (!state.isInitialized || !state.isAuthenticated) {
    return (
      null // TODO apply loading
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;