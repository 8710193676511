import styled from "styled-components";

const ContentClock = styled.div`
    .clock{
        color: ${(props) => props.theme.palette.colors.grayCard} !important;
        width: 40vw !important;
        height: 100% !important;
        font-family: 'Proxima Nova' !important;
        padding-right: 1vw !important;
        gap: 0.5vw !important;
        .data {
            .day {
                font-size: 2.5vw !important;
                line-height: 1;
            }
            .dataDay {
                font-size: 1.3vw !important;
                line-height: 1;
            }
        }
        .hour {
            .time {
                font-size: 4.5vw !important;
            }
        }
    }
`;

export { ContentClock };