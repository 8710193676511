import React, { type FC } from 'react'
import {ContentChart} from "./style"
import PropTypes from "./interface"
import { useAppSelector } from '../../app/hooks';
import { currentTheme } from '../../theme/hooks/themeSlice';

const ThemeChart: FC<PropTypes> = (props) => {
    const {
        children,
        height
    } = props
    const theme = useAppSelector(currentTheme);
    return(
        <ContentChart theme={theme} height={height}>
            {children}
        </ContentChart>
    )
}

export default ThemeChart;

