import styled from "styled-components";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const StyleTabs = styled(Tabs) <any>`
  box-shadow: none !important;
  min-height: 36px !important;
  &.d-none{
    display: none;
  }
  div[class*="MuiTabs-scroller"]{
    margin-bottom: 0 !important;
  }
  div[class*="ScrollButton"]{
    width: 36px !important;
    svg {
      color: #FFFFFF !important;
    }
    &[class*="disabled"]{
      opacity: 0.25 !important;
    }
  }
  span[class*="indicator"]{
    height: 36px !important;
    z-index: 1;
    border-radius: 2px !important;
  }
`;
const StyleTab = styled(Tab) <any>`
  height: 36px !important;
  min-height: 36px !important;
  padding: 7px 15px !important;
  text-transform: none !important;
  z-index: 2;
  svg{
    width: 14px;
    hidden: 14px;
  }
`;

export { StyleTabs, StyleTab };