import React, { type FC } from 'react'
import {Btn} from './style';
import ButtonPropsProperties from './interface';
import { useAppSelector } from "../../app/hooks";
import { currentTheme } from "../../theme/hooks/themeSlice";

const AtomicButtonHeader: FC<ButtonPropsProperties> = (props) => {

    const {
        text,
        href,
        className,
        onClick
    } = props
    
    const theme = useAppSelector(currentTheme);

    return (
        <Btn 
            theme={theme}
            href={href}
            className={className}
            onClick={onClick}
        >
            {text}
        </Btn>
    )
}

export default AtomicButtonHeader;