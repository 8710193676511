import styled from 'styled-components';
import { FontSize } from '../../utils/Constants/FontSize';

const WrapperMessage = styled.div <any>`
    color: ${props => props.theme.palette.layout.text}; 
    width: 100%;
    padding: 0.5vw;
    box-sizing: border-box;
    p{
        font-family: ${props => props.theme.typography.secondary};
        ${FontSize[11]};
        margin: 0 0 .75rem 0;
        font-weight: 400; 
        a{
            color: ${props => props.theme.palette.colors.primary}; 
        }
    }
`;

export { WrapperMessage };