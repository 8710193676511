import AtomicLoader from "./atom/loader";
import './assets/fonts/fonts.css';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { routes } from './routes'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "react-datepicker/dist/react-datepicker.css";
import GlobalStyles from './GlobalStyles'
import { useRealtime } from 'fs-widgets-core';
//@ts-ignore
import SockJsClient from 'react-stomp';
import { useAppSelector } from "./app/hooks";
import { currentTheme } from './theme/hooks/themeSlice';
import '@fontsource/work-sans/400.css';
import '@fontsource/work-sans/600.css';
import '@fontsource/work-sans/800.css';
const theme = createTheme({
  palette: {
    background: {
      paper: '#FFFFFF',
    }
  },
});

const App = () => {
  const { symbols, streamMessage } = useRealtime();

    const onMessage = (msg: any) => {
        streamMessage(msg);
    }

  const themeComponent = useAppSelector(currentTheme);
  return (
    <ThemeProvider theme={theme}>
        <GlobalStyles theme={themeComponent}/>
         <SockJsClient
                key={'realtime-broker'}
                url={process.env.REACT_APP_SOCKET_URL}
                onMessage={(message: any) => onMessage(message)}
                topics={symbols.map((topic: string) => `/topic/${topic}`)}
            >
            </SockJsClient>
          <AtomicLoader loading={false} loop />
          {/* CONTENT */}
          <>
            <Routes>
              {
                routes.map(
                  (route: any) => (
                    <Route path={route.path} element={route.component} />
                  )
                )
              }
            </Routes>
          </>
       </ThemeProvider>
  );
}

export default App;
