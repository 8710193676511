import { type FC } from 'react'
import {Toltip} from './style';
import PropsProperties from './interface';

const AtomicTooltip: FC<PropsProperties> = (props) => {
    
    const {
        title,
        children,
        show,
        disableHoverListener,
        onMouseEnter,
        onMouseLeave
    } = props

    return (
        <Toltip 
            title={title}
            open={show} 
            disableHoverListener={disableHoverListener}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <span>
                {children}
            </span>
        </Toltip>
    )
}

export default AtomicTooltip;