import { normalize } from "./Config";
export const FontSize: any = 
  {
    9: `font-size: clamp(8px, ${normalize(2)}vw, 10px) !important`,
    10: `font-Size: clamp(9px, ${normalize(2)}vw, 11px) !important`,
    11: `font-Size: clamp(10px, ${normalize(2)}vw, 12px) !important`,
    12: `font-Size: clamp(11px, ${normalize(2)}vw, 13px) !important`,
    13: `font-Size: clamp(12px, ${normalize(2)}vw, 14px) !important`,
    14: `font-Size: clamp(13px, ${normalize(2)}vw, 15px) !important`,
    16: `font-Size: clamp(15px, ${normalize(2)}vw, 17px) !important`,
    18: `font-Size: clamp(17px, ${normalize(2)}vw, 19px) !important`,
    20: `font-Size: clamp(18px, ${normalize(2)}vw, 22px) !important`,
    22: `font-Size: clamp(20px, ${normalize(2)}vw, 24px) !important`,
    24: `font-Size: clamp(22px, ${normalize(2)}vw, 26px) !important`,
    26: `font-Size: clamp(24px, ${normalize(2)}vw, 28px) !important`,
    28: `font-Size: clamp(26px, ${normalize(2)}vw, 30px) !important`,
    mini: `font-Size: clamp(5px, ${normalize(2)}vw, 9px) !important`,
    small: `font-Size: clamp(8px, ${normalize(2)}vw, 12px) !important`,
    medium: `font-Size: clamp(10px, ${normalize(2)}vw, 13px) !important`,
    large: `font-Size: clamp(14px, ${normalize(2)}vw, 18px) !important`,
    xlarge: `font-Size: clamp(22px, ${normalize(2)}vw, 26px) !important`,
    label: `font-Size: clamp(6px, ${normalize(2)}vw, 10px) !important`,
  }

 