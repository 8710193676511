import { createGlobalStyle } from "styled-components";
import { FontSize } from "./utils/Constants/FontSize";

const GlobalStyles = createGlobalStyle<any>`
      *:not(.ag-checkbox-input-wrapper, .ag-icon),
      [class*="MuiTypography"]{
            font-family: ${props => props.theme.typography.primary}, sans-serif !important;
      }

      body {
            margin: 0;
            background-color: ${props => props.theme.palette.layout.bg} !important;
      }

      button {
            font-weight: 500;
            &[class*="Error"]{
                  background-color: ${props => props.theme.palette.colors.error} !important;
                  &[class*="disabled"] {
                        background-color: ${props => props.theme.palette.colors.disabled} !important;
                  }
            }
      }
      .layout-main{
            padding-top: 10px;
            padding-left: 10px;
      }
      /* DRAWER HEADER */
      .drawer-header{
            background-color: ${props => props.theme.palette.dialog.bg} !important;
            color: ${props => props.theme.palette.dialog.text} !important;
            .header{
                  button svg{
                        color: ${props => props.theme.palette.dialog.text} !important;
                  }
            }
            
            /* input datePicker and select*/
            div[class*="MuiTextField"]{

                  label{
                        color: ${props => props.theme.palette.layout.text} !important;
                  }
                  
                  [class*="disabled"]{
                        -webkit-text-fill-color: ${props => props.theme.palette.layout.text} !important;
                  }
                  .MuiSelect-select{
                        color:  ${props => props.theme.palette.layout.text} !important;
                        border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                        font-family: ${props => props.theme.typography.primary}, sans-serif;

                        &~ input{
                              border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                              font-family: ${props => props.theme.typography.primary}, sans-serif;
                        }
                  }
                  
                  [class*="MuiSelect-iconStandard"]{
                        color: ${props => props.theme.palette.layout.text} !important;
                  }
            }
      }
      /* SELECT MENU LIST */
      div[class*="MuiMenu-paper"]{
            background-color: ${props => props.theme.palette.dialog.bg} !important;
            ul{
                  li{
                        background-color: ${props => props.theme.palette.dialog.bg} !important;
                        color:  ${props => props.theme.palette.dialog.text} !important;
                        ${FontSize[11]};
                        
                        [class*="MuiListItemIcon"]{
                              svg{ 
                                    fill:  ${props => props.theme.palette.dialog.text} !important;
                              }
                              [class*="MuiCheckbox"]{
                                    > input ~ svg{
                                          fill:  ${props => props.theme.palette.colors.primary} !important;
                                    }
                              }
                        }
                        &:hover{
                              background-color: ${props => props.theme.palette.card.hover} !important;
                        }
                  }
            }
      }
      
      /* LABEL RADIO */
      [class*="MuiFormGroup"] [class*="MuiFormControlLabel"] {
            [class*="label"] {
                  color: ${props => props.theme.palette.layout.text} !important;
                  font-family: unset !important;
            }
      }

      /* END SELECT MENU LIST */
      [class*="MuiCheckbox"]{
            > input ~ svg{
                  fill:  ${props => props.theme.palette.colors.primary} !important;
            }
      }
      /* DIALOG */
      .MuiDialog-container{
            div[class*="paper"]{
                  background-color: ${props => props.theme.palette.dialog.bg} !important;
                  color:  ${props => props.theme.palette.dialog.text} !important;
                  h2{
                        font-family: ${props => props.theme.typography.primary}, sans-serif;
                        ${FontSize[13]};
                        border-bottom: 1px solid;
                        border-color: ${props => props.theme.palette.card.header} !important;
                        margin-bottom: 1rem;
                        padding-bottom: 8px !important;
                        button{
                              svg{
                                    color:  ${props => props.theme.palette.dialog.text} !important;
                              }
                        }
                  }
                  .selected-instrument{
                        ${FontSize[11]};
                  }
                  span[class*="MuiSwitch"]{
                        &~span[class*="label"]{
                              color:  ${props => props.theme.palette.dialog.text} !important;
                        }
                  }
                  div[class*="MuiDialogContent"]{
                        ${FontSize[11]};
                        color:  ${props => props.theme.palette.dialog.text} !important;
                        
                        [class*="MuiDialogContentText"]{
                              ${FontSize[11]};
                              color:  ${props => props.theme.palette.dialog.text} !important;
                        }
                        /* bodyModal */
                        div[class*="MuiFormControl"] {
                              label{
                                    color:  ${props => props.theme.palette.dialog.text} !important;
                                    font-family: ${props => props.theme.typography.primary}, sans-serif !important;
                                    ${FontSize[12]};
                              }
                              div[class*="MuiInputBase"]{
                                    color:  ${props => props.theme.palette.dialog.text} !important;
                                    ${FontSize[11]};

                                    input{
                                          border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                          color:  ${props => props.theme.palette.dialog.text} !important;
                                          ${FontSize[11]};
                                    }
                                    svg{
                                          color:  ${props => props.theme.palette.dialog.text} !important;
                                    }
                              }
                              [class*="disabled"]{
                                    -webkit-text-fill-color: ${props => props.theme.palette.dialog.text} !important;
                              }
                              div[class*="adornedEnd"]{
                                    color:  ${props => props.theme.palette.layout.text} !important;
                                    border: 0;
                                    input{
                                          color:  ${props => props.theme.palette.layout.text} !important;
                                          &::placeholder{
                                                color:  ${props => props.theme.palette.layout.text} !important;
                                          }
                                    }
                                    > div[class*="positionEnd"] {
                                          button{
                                          color:  ${props => props.theme.palette.layout.text} !important;
                                          }
                                    }
                                    
                                    [class*="notchedOutline"]{
                                          border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    }
                              }
                              div[class*="adornedStart"]{
                                    color:  ${props => props.theme.palette.layout.text} !important;
                                    border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    > div p{
                                          color:  ${props => props.theme.palette.layout.text} !important;
                                    }
                              }
                              .MuiSelect-select{
                                    border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    &~ input{
                                          border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    }
                              }
                        }
                        input:checked + label div{
                              border-color: transparent !important;
                              svg{
                                    stroke: ${props => props.theme.palette.colors.primary} !important;
                              }
                        }
                        /* FINANCIAL SEARCH */
                        .filters{
                              button[class*="Secondary"]{
                                    background-color:  ${props => props.theme.palette.colors.primary} !important;
                              }
                        }
                        .result{
                              color:  ${props => props.theme.palette.layout.text} !important;
                        }
                        .itemSearch{
                              .left{
                                    .ticker, .tikerName{
                                          color:  ${props => props.theme.palette.layout.text} !important;
                                          font-family: ${props => props.theme.typography.primary}, sans-serif;
                                    }
                              }
                              .right{
                                    button{
                                          color:  ${props => props.theme.palette.layout.text} !important;
                                    }
                              }
                        }
                        /* TABLE INNER DIALOG*/
                        .ag-theme-alpine{
                              div{
                                    .ag-header-row{
                                          color: ${props => props.theme.palette.dialog.tableHeaderText} !important;
                                    }
                                    .ag-cell{
                                          color: ${props => props.theme.palette.dialog.text} !important;
                                          font-family: ${props => props.theme.typography.primary}, sans-serif;
                                    }
                                    .ag-row-even{
                                          background-color: ${props => props.theme.palette.dialog.tableRow} !important;
                                    }
                                    .ag-header-select-all{
                                          .ag-checkbox-input-wrapper{
                                                &::after{
                                                      color: ${props => props.theme.palette.colors.primary} !important;
                                                }
                                          }
                                    }
                                    .ag-checkbox-input-wrapper{
                                          &::after{
                                                color: ${props => props.theme.palette.colors.primary} !important;
                                          }
                                    }
                                    .ag-select .ag-picker-field-wrapper{
                                          border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    }
                                    .grid-content-input{
                                          border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    }
                                    .ag-popup-child{
                                          background-color: ${props => props.theme.palette.dialog.bg} !important;
                                          color:  ${props => props.theme.palette.dialog.text} !important;
                                          font-family: ${props => props.theme.typography.primary}, sans-serif;
                                    }
                              }
                        }
                        /* END TABLE INNER DIALOG */
                  }
                  div[class*="MuiDialogActions"]{
                        button{
                              font-family: ${props => props.theme.typography.primary}, sans-serif !important;
                              line-height: 1;
                              border-radius: 3px !important;
                              &[class*="Error"]{
                                    background-color: transparent !important;
                                    color: ${props => props.theme.palette.colors.primary} !important;
                                    border: 1px solid !important;
                                    border-color: ${props => props.theme.palette.colors.primary} !important;
                              }

                              &[class*="Secondary"] {
                                    background-color: ${props => props.theme.palette.colors.primary} !important;

                                    &[class*="disabled"] {
                                          background-color: ${props => props.theme.palette.colors.disabled} !important;
                                    }
                              }
                        }
                  }
            }
            
            div[class*="paperFullScreen"] h2{
                  background-color: ${props => props.theme.palette.header.bg} !important;
                  color:  ${props => props.theme.palette.header.text} !important;
                  button svg{
                        color:  ${props => props.theme.palette.header.text} !important;
                  }
            }
      }
      
      /* END DIALOG */

      /* SEARCH-SPECIAL */
      div[class*="control"]{
            ${FontSize[11]};
            font-family: ${props => props.theme.typography.primary}, sans-serif;
            border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
            >div[class*="ValueContainer"], >div:first-child{
                  div{
                        color:  ${props => props.theme.palette.dialog.text} !important;
                  }
                  div[class*="placeholder"]{
                        color:  ${props => props.theme.palette.dialog.text} !important;
                        ${FontSize[11]};
                        font-family: ${props => props.theme.typography.primary}, sans-serif;
                  }
                  div[class*="Input"]{
                        color:  ${props => props.theme.palette.dialog.text} !important;
                        font-family: ${props => props.theme.typography.primary}, sans-serif;
                  }
                  div[class*="multiValue"]{
                        background-color: ${props => props.theme.palette.colors.primary} !important;
                        >div:last-child{
                              color: #FFFFFF !important;
                              font-family: ${props => props.theme.typography.primary}, sans-serif;
                        }
                        .itemSelect{
                              .left{
                                    div{
                                          color: #FFFFFF !important; 
                                          font-family: ${props => props.theme.typography.primary}, sans-serif;
                                    }
                              }
                        }
                  }
            }
            >div[class*="IndicatorsContainer"], >div:last-child{
                  div[class*="indicatorContainer"], div{
                        color: ${props => props.theme.palette.dialog.text} !important;
                  }
            }
            
            &~ div[class*="MenuPortal"], &~ div {
                  font-size: 12px !important;
                  font-family: ${props => props.theme.typography.primary}, sans-serif;
                  background-color: ${props => props.theme.palette.dialog.bg} !important;
                  > div > div{
                        background-color: ${props => props.theme.palette.dialog.bg} !important;
                        > div, div[class*="NoOptionsMessage"] {
                              font-size: 12px !important;
                        }
                        .itemSelect{
                              .left{
                                    div{
                                          font-family: ${props => props.theme.typography.primary}, sans-serif !important;
                                          font-weight: 700;
                                          color: ${props => props.theme.palette.layout.text} !important;
                                    }
                                    .name{
                                          font-weight: 400;
                                          color: ${props => props.theme.palette.layout.text} !important;
                                    }
                              }
                        }
                  }
            }
      }
      /* END SEARCH-SPECIAL */

      /* SEARCH-WIDGET */
      span.result{
            ${FontSize[10]};
      }
      .itemSearch{
            padding: 6px 5px !important;
            .left{
                  .ticker{
                        ${FontSize[11]};
                  }
                  .tikerName{
                        ${FontSize[11]};
                  }
            }
      }
      /* END SEARCH-WIDGET */

      
      
      /* TABLE */
      .ag-theme-alpine{
            div{
                  .ag-header-row{
                        color: ${props => props.theme.palette.table.headerText} !important;
                  }
                  .ag-cell{
                        color: ${props => props.theme.palette.table.text} !important;
                        font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                  }
                  .ag-cell-wrap-text{
                        word-break: break-word;
                  }
                  .ag-row-even{
                        background-color: ${props => props.theme.palette.table.row} !important;
                  }
                  .ag-header-select-all{
                        .ag-checkbox-input-wrapper{
                              &::after{
                                    color: ${props => props.theme.palette.colors.secondary} !important;
                              }
                        }
                  }
                  .ag-checkbox-input-wrapper{
                        &::after{
                              color: ${props => props.theme.palette.colors.secondary} !important;
                              top: 0 !important;
                        }

                        &:focus-within{
                              box-shadow: 0 0 2px 0.1rem rgba(255, 80, 0, 0.4);
                        }
                  }
                  .ag-select .ag-picker-field-wrapper{
                        background-color: transparent !important;
                        border-color: ${props => props.theme.palette.line.border} !important;
                  }
                  .grid-content-input{
                        ${FontSize[11]};
                        border-color: ${props => props.theme.palette.line.border} !important;
                  }
                  .ag-popup-child{
                        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgb(0, 0, 0, .12) !important;
                        background-color: ${props => props.theme.palette.dialog.bg} !important;
                        color:  ${props => props.theme.palette.dialog.text} !important;
                        padding: 5px;
                  }
            }
      }
      /* END TABLE */
      
      .wrapper-no-data{
            color: ${props => props.theme.palette.layout.text} !important;
            .message{
                  ${FontSize[10]};
                  font-family: ${props => props.theme.typography.primary}, sans-serif;
            }
      }

      /* SCROLL */
      *{
            scrollbar-color: ${props => props.theme.key === 'dark' ? '#4b7da5' : 'rgba(0,0,0,0.5)'} rgba(0,0,0,0) !important;
            scrollbar-width: thin !important;
      }
      ::-webkit-scrollbar, * ::-webkit-scrollbar {
            all:unset;
            width: 8px;
            height: 8px;
      }

      ::-webkit-scrollbar-thumb, * ::-webkit-scrollbar-thumb {
            all:unset;
            background: ${props => props.theme.palette.scroll.bg} !important;
            border-radius: 4px;
      }

      ::-webkit-scrollbar-track, * ::-webkit-scrollbar-track {
            background:  ${props => props.theme.key === 'dark' ? '#4b7da5' : 'rgba(0,0,0,0.1)'};
      }

      ::-webkit-scrollbar-thumb {
            -webkit-border-radius:auto !important;
            border-radius:auto !important;
            background:auto !important;
            -webkit-box-shadow:auto !important;
      }
      /* END SCROLL */

      /* ALERTS */
      .snack-alert{
            font-family: ${props => props.theme.typography.primary} !important;
      }
      .snack-title-alert{
            font-family: ${props => props.theme.typography.primary} !important;
      }

      /* VIDEO */
      .video-react-control-bar{
            div[class*="control"]{
                  &~ div {
                        background-color: transparent !important;
                  }
            }
      }

      /* REACT DATE PICKER */
      .react-datepicker{
            font-family: ${props => props.theme.typography.secondary}, Helvetica, Arial, Serif, Sans-serif, cursive, fantasy, Monospace;
            background-color:  ${props => props.theme.palette.dialog.bg} !important;
            border: none !important;
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14), 0px 3px 14px 2px rgb(0, 0, 0, .12) !important;
      }
      .react-datepicker-popper{
            z-index: 9999 !important;
      }
      .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
            border-bottom-color: ${props => props.theme.palette.dialog.bg} !important;;
      }
      /* header */
      .react-datepicker__header{
            background-color: transparent !important;
            border-bottom-color: rgba(0,0,0,0.3) !important;
      } 
      .react-datepicker__navigation{
            border: none !important;
      }
      .react-datepicker__navigation:hover{
            background-color: transparent !important;
      }
      .react-datepicker__current-month, .react-datepicker__day-name{
            color: ${props => props.theme.palette.dialog.text} !important;;
      }

      /* body */
      .date-grid-container .date-grid.days-names{
            border-bottom: 0 !important;
            color: ${props => props.theme.palette.dialog.text} !important;
      }
      .react-datepicker__day{
            color: ${props => props.theme.palette.dialog.text} !important;
            border-radius: 50% !important;
      }

      .react-datepicker__day--keyboard-selected {
            background-color: ${props => props.theme.palette.colors.primary} !important;
            color: ${props => props.theme.palette.header.text} !important;
      }

      /* .react-datepicker__day--selected, 
      .react-datepicker__day--in-selecting-range {
            background-color: ${props => props.theme.palette.colors.primary} !important;
            border: none !important;
            transition: all ease-in !important;
      } */

      .react-datepicker__day--in-range,
      .react-datepicker__day--selected {
            background-color: ${props => props.theme.palette.colors.primary} !important;
            color: white !important;
            transition: all .2s ease-in !important;
      }
      
      .react-datepicker__day:hover{
            background-color: ${props => props.theme.palette.colors.secondary} !important;
            color: white !important;
      }
      .react-datepicker__triangle{
            display: none;
      }
      .react-datepicker__day--disabled{
            cursor: default;
            opacity: 0.75;
            background-color:  ${props => props.theme.palette.table.row};
            border-radius: 50%;
            &:hover{
                  opacity: 0.75;
                  background-color: ${props => props.theme.palette.table.row} !important;
                  color: ${props => props.theme.palette.dialog.text} !important;
                  border-radius: 50%;
            }
      }
      
      .react-datepicker__day--outside-month{
            opacity: 0.25;
            background-color:  ${props => props.theme.palette.table.row};
            color: ${props => props.theme.palette.dialog.text} !important;

            &:hover{
                  background-color:  ${props => props.theme.palette.table.row} !important;
                  color: ${props => props.theme.palette.dialog.text} !important;
                  opacity: 0.25;
            }
      }
      /* FIN REACT DATE PICKER */

      /* POPPER PICKER */
      div[class*="MuiPickersPopper"]{
            background-color: ${props => props.theme.palette.dialog.bg} !important;
            color: ${props => props.theme.palette.dialog.text} !important;
            div[class*="MuiCalendarPicker"]{
                  div[class*="MuiPickersCalendarHeader"]{
                        div[class*="labelContainer"]{
                              font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                              button{
                                    color: ${props => props.theme.palette.dialog.text} !important;
                              }
                        }
                        div[class*="MuiPickersArrowSwitcher"]{
                              button{
                                    color: ${props => props.theme.palette.dialog.text} !important;
                              }
                        }
                  }
                  div[class*="viewTransitionContainer"]{
                        div[class*="MuiDayPicker-header"]{
                              span{
                                    color: ${props => props.theme.palette.table.headerText} !important;
                              }
                        }
                        div[class*="PrivatePickersSlideTransition"]{
                              div[class*="monthContainer"]{
                                    div[class*="weekContainer"]{
                                          div[class*="MuiPickersDay"], button[class*="MuiPickersDay"]{
                                                color: ${props => props.theme.palette.dialog.text} !important;
                                                font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                                                &[class*="selected"]{
                                                      background-color: ${props => props.theme.palette.colors.primary} !important;
                                                      color: white !important;
                                                }
                                          }
                                    }
                              }
                        }
                        button[class*="PrivatePickersYear"]{
                              color: ${props => props.theme.palette.dialog.text} !important;
                              font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                              &[class*="selected"]{
                                    background-color: ${props => props.theme.palette.colors.primary} !important;
                                    color: white !important;
                              }
                        }
                  }
            }
      }
      /* FIN POPPER PICKER */
      /* POPPER */
      div[class*="MuiPopper"]{
            div[class*="MuiPaper"]{
                  background-color: ${props => props.theme.palette.dialog.bg} !important;
                  label{
                        color: ${props => props.theme.palette.dialog.text} !important;
                        font-family: ${props => props.theme.typography.primary}, sans-serif !important;
                  }
                  > li{
                        background: transparent !important;
                        color: ${props => props.theme.palette.dialog.text} !important;
                        &:hover{
                              background-color: ${props => props.theme.palette.card.hover} !important;
                        }
                  }
                  
                  div[class*="MuiFormControl"]{
                              div[class*="MuiInputBase"]{
                                    color:  ${props => props.theme.palette.dialog.text} !important;
                                    ${FontSize[11]};

                                    input{
                                          color:  ${props => props.theme.palette.dialog.text} !important;
                                          border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                          ${FontSize[11]};
                                          font-family: ${props => props.theme.typography.primary}, sans-serif;
                                    }
                                    svg{
                                          color:  ${props => props.theme.palette.dialog.text} !important;
                                    }
                              }
                              
                              div[class*="adornedStart"]{
                                    color:  ${props => props.theme.palette.layout.text} !important;
                                    border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                                    > div p{
                                          color:  ${props => props.theme.palette.layout.text} !important;
                                    }
                              }
                        }
                  .scroll-style{
                        > li{
                              background: transparent !important;
                              color: ${props => props.theme.palette.dialog.text} !important;
                              &:hover{
                                    background-color: ${props => props.theme.palette.card.hover} !important;
                              }
                              button{
                                    color: ${props => props.theme.palette.dialog.text} !important;
                                    &.fillBg{
                                          svg{
                                                fill: ${props => props.theme.palette.colors.primary} !important;
                                                stroke: ${props => props.theme.palette.colors.primary} !important;
                                          }
                                    }
                              }
                        }
                  }
            }
      }
      /* FIN POPPER */

      /* COLOR PICKER */
      .sketch-picker{
            background-color: ${props => props.theme.palette.dialog.bg} !important;
            box-shadow: 0px 4px 4px 0px rgba(64, 64, 64, 0.25) !important;
            .flexbox-fix{
                  input{
                        background-color: transparent;
                        color: ${props => props.theme.palette.layout.text} !important; 
                        box-shadow: none !important;
                        border: 1px solid !important;
                        border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important; 
                  }
                  label{
                        color: ${props => props.theme.palette.layout.text} !important; 
                  }
            }
            .flexbox-fix:last-child{
                  border-color: ${props => props.theme.palette.line.gray50} !important; 
            }
      }

      /* LOADER */
      .wrapper-icon-loading{
            svg{
                  fill: ${props => props.theme.palette.colors.primary} !important;
            }
      }
      .icon-loading{
            fill: ${props => props.theme.palette.colors.primary} !important;
      }
`;

export default GlobalStyles;
