import styled from 'styled-components';

const Layout = styled.main <any>`
    position: relative;
    overflow: auto;
    height: calc(100% - 60px);
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-areas:
        "ticker"
        "watchlist"
        "footer"
    ;
    grid-template-columns: 1fr;
    grid-template-rows: 24px calc(100% - 40px) auto;
    gap: 16px;
    .widget-ticker{
        grid-area: ticker;
        display: flex;
        align-items: center;
        position: relative;
        > .small{
            width: calc(100vw - 20px);
            display: inline-block;
            @media only screen and (max-width: 599px){
                width: calc(100vw - 20px);
            }
        }
    }
    
    .detail-watchlist {
        grid-area: watchlist;
        > .body {
            height: calc(100% - 60px);
            > div { 
                // contentWatchlist
                height: calc(100% - 40px) !important;
            }
        }

        #full-width-tabpanel-1 .ag-theme-alpine {
            .ag-cell{
                text-align: right;
            }

            .ag-header-cell {
                &[aria-colindex="2"],
                &[aria-colindex="3"],
                &[aria-colindex="4"],
                &[aria-colindex="5"],
                &[aria-colindex="6"],
                &[aria-colindex="7"],
                &[aria-colindex="8"] {
                    .ag-header-cell-label {
                        justify-content: right;
                    }
                } 
            }
        }

        #full-width-tabpanel-0 .ag-theme-alpine {
            .ag-header-cell {
                &[aria-colindex="2"],
                &[aria-colindex="3"],
                &[aria-colindex="4"],
                &[aria-colindex="5"],
                &[aria-colindex="6"] {
                    .ag-header-cell-label {
                        justify-content: right;
                    }
                } 
            }
            .ag-center-cols-viewport {
                .ag-cell-value {
                    text-align: right;
                }
            }
        }
    }

    .footer {
        grid-area: footer;
    }
`;

export { Layout };