import styled from 'styled-components';

const Layout = styled.main <any>`
    position: relative;
    overflow: auto;
    height: calc(100% - 60px);
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-areas:
        "ticker"
        "watchlist"
    ;
    grid-template-columns: 1fr;
    grid-template-rows: 24px calc(100% - 40px);
    gap: 16px;
    .widget-ticker{
        grid-area: ticker;
        display: flex;
        align-items: center;
        position: relative;
        > .small{
            width: calc(100vw - 150px);
            display: inline-block;
            @media only screen and (max-width: 599px){
                width: calc(100vw - 100px);
            }
        }
    }
    
    .detail-watchlist {
        grid-area: watchlist;
        > .body {
            height: calc(100% - 60px);
            > div { // contentWatchlist
                height: calc(100% - 40px) !important;
            }
        }
    }
`;

export const LayoutTicker = styled.div <any>`
    position: relative;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    display: block;

    .widget-ticker{
        grid-area: ticker;
        display: flex;
        align-items: center;
        position: relative;
        > .small{
            width: calc(100vw - 150px);
            display: inline-block;
            @media only screen and (max-width: 599px){
                width: calc(100vw - 100px);
            }
        }
    }

    .detail-watchlist {
        grid-area: watchlist;
        > .body {
            height: calc(100% - 60px);
            > div { // contentWatchlist
                height: calc(100% - 40px) !important;
            }
        }
    }
`;

export { Layout };