import React, { type FC } from "react";
import PropsInterface from "./interface";
import { useAppSelector } from "../../app/hooks";
import { currentTheme } from "../../theme/hooks/themeSlice";
import { WrapperCard, Light } from "./style";
import Hidden from '@mui/material/Hidden';

const OnLine: FC<PropsInterface> = (props) => {
  const theme = useAppSelector(currentTheme);
  const { active } = props;
  return (
    <WrapperCard theme={theme} target="_blank" href='https://www.principal.cl/globalmarkets'>
      <Light theme={theme} className={active ? 'success' : 'error'}/>
      <p>
        <Hidden smDown>
          Ver mercados en línea
        </Hidden>
        <Hidden smUp>
          VER MÁS
        </Hidden>
      </p>
    </WrapperCard>
  );
};

export default OnLine;
