import Colors from '../utils/Constants/Colors'
import Fonts from '../utils/Constants/Fonts'
const FSTheme = {
    ligth : {
        logo: 'https://trader-demo.finsol.cloud/static/media/logo-inverse.64e46c2657fbd3f299cfb0a46479d666.svg',
        typography: {
            primary: Fonts.primary,
            secondary: Fonts.secondary,
        },
        palette: {
            layout : {
                bg : Colors.light,
                text : Colors.text,
                logo : Colors.logoText,
            },
            header : {
                bg : Colors.primary,
                text : Colors.white,
            },
            menu : {
                bg : Colors.light,
                text : Colors.text,
                icon : Colors.neutral2,
                bgActive : Colors.primary,
                textActive : Colors.white,
                iconActive : Colors.white,
            },
            colors : {
                primary : Colors.primary,
                secondary : Colors.secondary,
                tertiary: Colors.tertiary,
                error : Colors.error,
                success : Colors.success,
                warning : Colors.warining,
                info : Colors.info,
                gray : Colors.neutral1,
                up : Colors.up1,
                up2 : Colors.up2,
                up3 : Colors.up3,
                down : Colors.down1,
                down2 : Colors.down2,
                down3 : Colors.down3,
                disabled: Colors.neutral3,
            },
            line : {
                gray50 : Colors.neutral4,
                border: Colors.neutral5,
            },
            card : {
                bg : Colors.white,
                text : Colors.text2,
                header : Colors.primary,
                headerText : Colors.white,
                hover : Colors.hover,
                tab : Colors.quaternary,
            },
            table : {
                row : Colors.neutral5,
                text : Colors.text,
                headerText : Colors.text2,
            },
            chart: {
                slider: Colors.primary,
                text: Colors.text,
                bg: Colors.neutral5,
                bgButton: Colors.neutral3,
                linePrimary: Colors.chart,
                lineGrid: Colors.neutral4,
            },
            accordion: {
                headerBg: Colors.primary,
                headerText: Colors.white,
                headerInnerBg: Colors.neutral4,
                headerInnerText: Colors.black,
                contentText: Colors.text,
            },
            configChart: {
                bgColorPicker: Colors.light,
                colorPicker: Colors.text,
                selectBg: Colors.primary,
                selectColor: Colors.white,
                inputCounter: Colors.neutral4,
                borderInputCounter: Colors.neutral4,
            },
            dialog : {
                bg : Colors.light,
                text : Colors.text,
                tableRow: Colors.neutral5,
                tableText: Colors.text,
                tableHeaderText: Colors.text2,
                tableBorderInput: Colors.quaternary,
                dark: Colors.dark,
            },
            scroll : {
                bg : Colors.neutral3,
            },
        },
        key: 'ligth'
    },
    dark : {
        logo: 'https://trader-demo.finsol.cloud/static/media/logo-inverse.64e46c2657fbd3f299cfb0a46479d666.svg',
        typography: {
            primary: Fonts.primary,
            secondary: Fonts.secondary,
        },
        palette: {
            layout : {
                bg : Colors.dark,
                text : Colors.textDark,
                logo : Colors.white,
            },
            header : {
                bg : Colors.primary,
                text : Colors.white,
            },
            menu : {
                bg : Colors.dark,
                text : Colors.textDark,
                icon : Colors.textDark,
                bgActive : Colors.primary,
                textActive : Colors.white,
                iconActive : Colors.white,
            },
            colors : {
                primary : Colors.primary,
                secondary : Colors.secondary,
                tertiary: Colors.tertiary,
                error : Colors.errorDark,
                success : Colors.successDark,
                warning : Colors.warining,
                info : Colors.info,
                gray : Colors.neutral1Dark,
                up : Colors.up1Dark,
                up2 : Colors.up2Dark,
                up3 : Colors.up3Dark,
                down : Colors.down1Dark,
                down2 : Colors.down2Dark,
                down3 : Colors.down3Dark,
                disabled: Colors.neutral3Dark,
            },
            line : {
                gray50 : Colors.neutral4Dark,
                border: Colors.neutral5Dark,
            },
            card : {
                bg : Colors.tertiary,
                text : Colors.textDark,
                header : Colors.neutral4Dark,
                headerText : Colors.text2,
                hover : Colors.hoverDark,
                tab : Colors.quaternary,
            },
            table : {
                row : Colors.neutral5Dark,
                text : Colors.textDark,
                headerText : Colors.text2Dark,
            },
            chart: {
                slider: Colors.primary,
                text: Colors.textDark,
                bg: Colors.neutral5Dark,
                bgButton: Colors.neutral3Dark,
                linePrimary: Colors.chart,
                lineGrid: Colors.neutral4Dark,
            },
            accordion: {
                headerBg: Colors.primary,
                headerText: Colors.white,
                headerInnerBg: Colors.neutral4Dark,
                headerInnerText: Colors.textDark,
                contentText: Colors.textDark,
            },
            configChart: {
                bgColorPicker: Colors.dark,
                colorPicker: Colors.textDark,
                selectBg: Colors.primary,
                selectColor: Colors.white,
                inputCounter: Colors.neutral4Dark,
                borderInputCounter: Colors.neutral4Dark,
            },
            dialog : {
                bg : Colors.neutral2Dark,
                text : Colors.textDark,
                tableRow: Colors.neutral5Dark,
                tableText: Colors.textDark,
                tableHeaderText: Colors.text2Dark,
                tableBorderInput: Colors.primary,
                dark: Colors.dark,
            },
            scroll : {
                bg : Colors.neutral3Dark,
            },
        },
        key: 'dark'
    }
};
export default FSTheme;