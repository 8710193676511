import styled from 'styled-components';
import { FontSize } from '../../../utils/Constants/FontSize';

const Layout = styled.main <any>`
    position: relative;
    overflow: auto;
    height: calc(100% - 60px);
    box-sizing: border-box;
    padding: 10px;
    display: grid;
    grid-template-areas:
        "ticker ticker ticker"
        "indices monedas grafica"
        "indices commodities grafica"
        "content content content"
        "footer footer footer"
        ;
    grid-template-columns: .5fr .5fr 1fr;
    grid-template-rows: 24px auto auto auto auto;
    gap: 16px;
    .widget-ticker{
        grid-area: ticker;
        display: flex;
        align-items: center;
        position: relative;
        > .small{
            width: calc(100vw - 20px);
            display: inline-block;
            @media only screen and (max-width: 599px){
                width: calc(100vw - 20px);
            }
        }
    }
    .indices{
        grid-area: indices;
        div[col-id=col_1] {
            width: 50% !important;
        }
            div[col-id=col_2] {
                left: 50% !important;
                text-align: right;
                width: 25% !important;

            .ag-header-cell-label {
                justify-content: right;
            }
        }
            div[col-id=col_3] {
                left: 75% !important;
                text-align: right;
                padding-right: 15px !important;
                width: 25% !important;

                .ag-header-cell-label {
                justify-content: right;
            }
        }
    }
    .monedas{
        grid-area: monedas;

        div[col-id=col_1] {
            width: 50% !important;
        }
        div[col-id=col_2] {
            left: 50% !important;
            text-align: right;
            width: 25% !important;

            .ag-header-cell-label {
                justify-content: right;
            }
        }
        div[col-id=col_3] {
            left: 75% !important;
            text-align: right;
            padding-right: 15px !important;
            width: 25% !important;

            .ag-header-cell-label {
                justify-content: right;
            }
        }
    }
    .commodities{
        grid-area: commodities;

        div[col-id=col_1] {
            width: 50% !important;
        }
        div[col-id=col_2] {
            left: 50% !important;
            text-align: right;
            width: 25% !important;

            .ag-header-cell-label {
                justify-content: right;
            }
        }
        div[col-id=col_3] {
            left: 75% !important;
            text-align: right;
            padding-right: 15px !important;
            width: 25% !important;

            .ag-header-cell-label {
                justify-content: right;
            }
        }
    }
    .grafica{
        grid-area: grafica;
        
        .tickerNameLarge {
            display: none;
        }
    }
    .footer{
        grid-area: footer;
    }
    .flex-content{
        display: grid;
        grid-area: content;
        gap: 16px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto auto 180px;
        grid-template-areas:
            "wl transadas tasas"
            "fondosRV fondosD fondosB"
            "ifram ifram ifram"
        ;
        .wl{
            grid-area: wl;
            div[col-id=col_1] {
            width: 50% !important;
        }
            div[col-id=col_2] {
            left: 50% !important;
            text-align: right;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            }
        }
            div[col-id=col_3] {
            left: 75% !important;
            text-align: right;
            padding-right: 15px !important;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            }
        }
        }
        .transadas{
            grid-area: transadas;
            div[col-id=col_1] {
                width: 50% !important;
            }
            div[col-id=col_2] {
                left: 50% !important;
                text-align: right;
                width: 25% !important;

                .ag-header-cell-label {
                justify-content: right;
            }
            }
            div[col-id=col_3] {
                left: 75% !important;
                text-align: right;
                padding-right: 15px !important;
                width: 25% !important;

                .ag-header-cell-label {
                justify-content: right;
            }
            }
        }
        .tasas{
            grid-area: tasas;

            div[col-id=col_1] {
            width: 50% !important;
        }
            div[col-id=col_2] {
            left: 50% !important;
            text-align: right;
            width: 25% !important;

            .ag-header-cell-label {
                justify-content: right;
            }
        }
            div[col-id=col_3] {
            left: 75% !important;
            text-align: right;
            padding-right: 15px !important;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            }
        }
        }
        .height-table {
            .ag-theme-alpine {
                height: 320px !important;
                overflow: hidden;
                .ag-center-cols-viewport {
                    height: 280px !important;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }
        }
        .fondos-rv{
            grid-area: fondosRV;
            div[col-id=col_1] {
            width: 50% !important;
        }
            div[col-id=col_2] {
            left: 50% !important;
            text-align: right;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            } 
        }
            div[col-id=col_3] {
            left: 75% !important;
            text-align: right;
            padding-right: 15px !important;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            }
        }
        }
        .fondos-d{
            grid-area: fondosD;
            div[col-id=col_1] {
            width: 50% !important;
        }
            div[col-id=col_2] {
            left: 50% !important;
            text-align: right;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            }
        }
            div[col-id=col_3] {
            left: 75% !important;
            text-align: right;
            padding-right: 15px !important;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            }
        }
        }
        .fondos-b{
            grid-area: fondosB;
            div[col-id=col_1] {
            width: 50% !important;
        }
            div[col-id=col_2] {
            left: 50% !important;
            text-align: right;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            }
        }
            div[col-id=col_3] {
            left: 75% !important;
            text-align: right;
            padding-right: 15px !important;
            width: 25% !important;
            .ag-header-cell-label {
                justify-content: right;
            }
        }
        }
        .ifram{
            grid-area: ifram;
            overflow: hidden;
            >div {
                padding: 0 !important;
            }
        }
    }

    @media only screen and (min-width: 789px) and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 24px auto auto 420px auto auto;
        grid-template-areas:
        "ticker ticker"
        "indices monedas"
        "indices commodities"
        "grafica grafica"
        "content content"
        "footer footer";

        .flex-content{
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto auto 180px;
            grid-template-areas:
                "wl transadas"
                "tasas fondosRV"
                "fondosD fondosB"
                "ifram ifram";
        }
    }
    
    @media only screen and (max-width: 788px) {
        gap: 10px;
        grid-template-columns: 1fr;
        grid-template-rows: 24px auto auto auto 460px auto auto;
        grid-template-areas:
        "ticker"
        "indices"
        "monedas"
        "commodities"
        "grafica"
        "content"
        "footer"
        ;
        .flex-content{
            gap: 10px;
            width: 100%;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto auto auto auto 380px;
            grid-template-areas:
                "wl"
                "transadas"
                "tasas"
                "fondosRV"
                "fondosD"
                "fondosB"
                "ifram"
            ;
        }
    }
`;

export { Layout };