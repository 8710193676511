import { INIT, REGISTER } from "./actions";

export const initialState = {
    user: null,
    accessToken: null,
    isAuthenticated: false,
    isInitialized: false
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case INIT: {
            const { isAuthenticated } = action.payload;
            return { ...state, isAuthenticated, isInitialized: true };
        }
        case REGISTER: {
            const { user, isAuthenticated, accessToken } = action.payload;
            return { ...state, user: user, isAuthenticated: isAuthenticated, accessToken: accessToken };
        }
        default: {
            return state;
        }
    }
};

export default reducer