import React, { useEffect } from 'react';
import {TickerLayout, OnLine} from '../../components';
import {ThemeTicker} from '../../widgets'
import { FinancialTicker } from 'fs-widgets-core';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../app/hooks';
import { changeTheme } from '../../theme/hooks/themeSlice';

export function Ticker() {
    const dispatch = useAppDispatch();
    const {theme} = useParams()
  
    useEffect(() => {
        if (theme === 'dark' || theme === 'ligth') {
        const handleTheme = (theme: 'dark' | 'ligth') => {
            dispatch(changeTheme(theme));
        };

        handleTheme(theme);
        }
    }, [theme, dispatch]);
    return (
        <>
            <TickerLayout>
                <section className="widget-ticker">
                    <ThemeTicker className="small">
                        <FinancialTicker
                            key={`main-FinancialTicker-${11}`}
                            instance={'36'} // 11 
                            language={'es_MX'} 
                        />
                    </ThemeTicker>
                    <OnLine active/> 
                </section>
            </TickerLayout>
        </>
    )
    
}