import React, { type FC } from 'react'
import {StyleTabs} from './style';
import TabsPropsProperties from './interface';

const AtomicTabs: FC<TabsPropsProperties> = (props) => {

    const {
        value,
        onChange,
        children,
        variant,
        line,
        className
    } = props

    return (
        <StyleTabs 
            value={value}
            onChange={onChange}
            variant={variant ? variant : 'scrollable'}
            line={line}
            scrollButtons="auto"
            allowScrollButtonsMobile
            className={className}
        >
            {children}
        </StyleTabs>
    )
}

export default AtomicTabs;