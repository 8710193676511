import React, { type FC } from "react";
import PropsInterface from "./interface";
import { useAppSelector } from "../../app/hooks";
import { currentTheme } from "../../theme/hooks/themeSlice";
import { WrapperMessage } from "./style";

const MessageFooter: FC<PropsInterface> = (props) => {
  const {
    className
} = props
  const theme = useAppSelector(currentTheme);
  return (
    <WrapperMessage className={className} theme={theme}>
        <p>La información de los Fondos Mutuos Principal corresponde a la Serie Inversionista, cuyos valores cuota y variación porcentual son al cierre del día anterior. Para información de otras series de fondos pueden ser consultadas en <a href="https://www.principal.cl/" target="_blank">Principal.cl.</a></p>
        <p>Para más información de los datos contenidos en este portal, <a download="Documento Legal" href="https://www.principal.cl/archivos/Documento-Legal-Footer.pdf" target="_blank">haz click aquí.</a></p>
        <p>Infórmese de las características esenciales de la inversión en estos fondos mutuos, las que se encuentran contenidas en sus reglamentos internos. La rentabilidad o ganancia obtenida en el pasado por estos fondos, no garantiza que se repita en el futuro. Los valores de los fondos mutuos son variables. Rentabilidad actualizada al último día hábil vigente.</p>
    </WrapperMessage>
  );
};

export default MessageFooter;
