import styled from 'styled-components';
import { FontSize } from '../../utils/Constants/FontSize';

const Paragraph = styled.p <any>`
    font-family: ${props => props.theme.typography.primary};  
    ${FontSize[11]};
    margin: 0;
    color: ${props => props.theme.palette.layout.text};
`;

export { Paragraph };