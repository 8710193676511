import styled, { css } from "styled-components";
import IconButton from '@mui/material/IconButton';

const ButtonIcon = styled(IconButton) <any>`
  padding: 0 !important;
  height: 28px !important;
  width: 28px !important;
  color: white !important;

  ${props => props.size === 'small' && 'width: 20px !important; height: 20px !important;'}
  ${props => props.size === 'medium' && 'width: 28px !important; height: 28px !important;'}
  ${props => props.size === 'large' && 'width: 33px !important; height: 33px !important;'}
  
  &.menu{
    height: 100% !important;
    width: 70px !important;
    border-radius: 0 !important;
    
    svg{
      width: 28px !important;
      height: 28px !important;
    }
  }

  ${props => props.modal && 'background-color: transparent !important;'}
  ${props => props.color === "warning" && 'background-color: #F2994A !important;'}
  ${props => props.color === "error" && 'background-color: #EB5757 !important;'}
  ${props => props.color === "success" && 'background-color: #1BC47D !important;'}
  ${props => props.color === "secondary" && 'background-color: #0098DD !important;'}

  ${props => props.fixed && css`
      position: absolute !important;
      z-index: 999;
  `}
  
  ${props => props.disabled && 'opacity: .6 !important;'}
  
  ${props => props.my && 'margin-top: .3rem !important; margin-bottom: .3rem !important;'}
  ${props => props.mx && 'margin-left: .5rem !important; margin-right: .5rem !important;'}
  ${props => props.ml && 'margin-left: .5rem !important;'}
  ${props => props.mr && 'margin-right: .5rem !important;'}
  ${props => props.mt && 'margin-top: .5rem !important;'}
  ${props => props.mb && 'margin-bottom: .5rem !important;'}
  
  svg{
    ${props => props.size === 'small' && 'width: 12px !important; height: 12px !important;'}
    ${props => props.size === 'medium' && 'width: 16px !important; height: 16px !important;'}
    ${props => props.size === 'large' && 'width: 20px !important; height: 20px !important;'}
    ${props => props.modal && 'color: white !important; fill: white !important;'}
    ${props => props.error && 'color: white !important; fill: white !important;'}
    ${props => props.coloricon === 'primary' && 'color: #002E9D !important; fill: #002E9D !important;'}
    ${props => props.coloricon === 'secondary' && 'color: #0098DD !important; fill: #0098DD !important;'}
    ${props => props.coloricon === 'success' && 'color: #1BC47D !important; fill: #1BC47D !important;'}
    ${props => props.coloricon === 'error' && 'color: #EB5757 !important; fill: #EB5757 !important;'}
    ${props => props.coloricon === 'warning' && 'color: #F2994A !important; fill: #F2994A !important;'}
  }
`;

export { ButtonIcon };