import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentTicker = styled.div`
    .animationArrow{
        border-color: ${props => props.theme.palette.line.gray50};
        color: ${props => props.theme.palette.layout.text};
        
        .nameTicker{
            ${FontSize[12]};
            font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
            font-weight: 600 !important;
        }

        .contentIcon .percentChange, 
        .contentIcon .netChange {
            color: ${props => props.theme.palette.layout.text};
        }
        
        .lastPrice, .percentChange, .netChange{
            ${FontSize[12]};
            font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
            font-weight: 600;
        }

        &[color^=down] {
            .contentIcon{
                svg, .percentChange, .netChange{
                    color: ${props => props.theme.palette.colors.down};
                }
            }
        }

        &[color^=up] {
            .contentIcon{
                svg, .percentChange, .netChange{
                    color: ${props => props.theme.palette.colors.up};
                }
            }
        }
    }
    &.small{
        .animationArrow{
            height: 24px;
            justify-content: center;
            &>div:last-child{
                display: none;
            }
        }
        
        div[color^=down] {
                .lastPrice{
                    color: ${props => props.theme.palette.colors.down};
                }
        }

        div[color^=up] {
            .lastPrice{
                color: ${props => props.theme.palette.colors.up};
            }
        }
    }
`;

export { ContentTicker };