import styled from "styled-components";
import { FontSize } from '../../utils/Constants/FontSize';

const ContentTable = styled.div<any>`
    & {
        height:  ${props => props.fullHeight && '100%'} !important;
    }
    .ag-theme-alpine{
        >div{
            background: transparent !important;
            .ag-header-row{
                color: ${props => props.theme.palette.table.headerText} !important;
            }
            .ag-row-even{
                background-color: ${props => props.theme.palette.table.row} !important;
            }
            .ag-cell{
                color: ${props => props.theme.palette.table.text} !important;
                

                &.down{
                    color: ${props => props.theme.palette.colors.down} !important;
                }
                &.up{
                    color: ${props => props.theme.palette.colors.up} !important;
                }
            }
            .ag-floating-filter .ag-floating-filter-input input{
                border-color: ${props => props.theme.palette.dialog.tableBorderInput} !important;
                
            }
            .ag-row{
                &.disabled{
                    .ag-cell:not(.up):not(.down){
                        opacity: 0.7 !important;
                    }
                }
            }
            .ag-header-cell-text{
                font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                ${FontSize[12]};
                font-weight: 400;
            }
            .ag-cell{
                ${FontSize[12]};
            }
            .ag-header-cell-resize{
                &:after{
                background-color: transparent;
                opacity: 0;
                }
            }
            .ticker{
                svg{
                    min-width: 16px;
                    width: 16px;
                    height: 16px;
                    color: ${props => props.theme.palette.layout.text} !important;
                }
                &[color^=down] {
                    svg{
                        color: ${props => props.theme.palette.colors.down} !important;
                    }
                }
                &[color^=up] {
                    svg{
                        color: ${props => props.theme.palette.colors.up} !important;
                    }
                }
                .flagImg{
                    width: 20px;
                }
                span{
                    ${FontSize[12]};
                    font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                    font-weight: 600;
                    color: ${props => props.theme.palette.table.headerText} !important;
                }
            }
            .ag-popup{
                > div {
                    padding: 0 !important;
                    .tickerTolltip{
                        background-color: ${props => props.theme.palette.header.bg} !important;
                        color: ${props => props.theme.palette.header.text} !important;
                        font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                        .contentActions{
                            div{
                                .buy{
                                    background-color: ${props => props.theme.palette.colors.success} !important;
                                }
                                .sell{
                                    background-color: ${props => props.theme.palette.colors.error} !important;
                                }
                                span{
                                    font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                                }
                            }

                        }
                        .range{
                            p{
                            font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                            }
                        }
                        .contentVol{
                            div{
                                span{
                                    font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                                }
                            }
                        }
                    }
                }
            } 
            .ag-header-select-all{
                .ag-checkbox-input-wrapper{
                &::after{
                    color: ${props => props.theme.palette.colors.primary} !important;
                }
                }
            }
            .ag-checkbox-input-wrapper{
                &::after{
                    color: ${props => props.theme.palette.colors.primary} !important;
                }
            }
        }
        .ag-body-viewport{
            min-height: 80px;
        }
        .ag-overlay-wrapper{
            padding-top: 20px;
        }
        .ag-overlay-loading-wrapper {
            background-color: transparent;
            .ag-overlay-loading-center{
                border: none;
                box-shadow: none;
                background-color: ${props => props.theme.palette.card.header};
                color: ${props => props.theme.palette.card.headerText};
            }
        }
        .ag-pinned-left-header {
            border-right-color: transparent !important;
        }
        .ag-ltr .ag-cell{
            border-right-width: 0 !important;
        }
        .ag-pinned-left-cols-container{
            z-index: 1;
        }
        .ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-left-spacer:not(.ag-scroller-corner){
            opacity: 0;
        }
    }
    /* TABS */
    div[class*="MuiTabs"]{
        >div[class*="MuiTabScrollButton"]{
            svg{
                color: ${props => props.theme.palette.layout.text} !important;
            }
        }
        button{
            color: #FFFFFF;
            ${FontSize[12]};
            background: ${props => props.theme.palette.card.tab};
            
            &:hover{
                opacity: 0.9;
            }
            &[class*="selected"]{
                font-weight: 700;
                background-color: ${props => props.theme.palette.colors.primary} !important;
            }
        }
        span[class*="MuiTabs-indicator"]{
            background-color: ${props => props.theme.palette.colors.primary} !important;
        }
    }
    /* MONITOR */
    .headerActions{
        /* search */
        div[class*="adornedEnd"]{
            border: none !important;
            padding-right: 0 !important;
            > div[class*="positionEnd"] {
                color:  ${props => props.theme.palette.layout.text} !important;
            }
            [class*="notchedOutline"]{
                border-color: ${props => props.theme.palette.line.border} !important;
            }
            input{
                ${FontSize[11]};
            }
        }
        .right{
            button{
                color: ${props => props.theme.palette.layout.text} !important;
            }
        }
    }
    

    button[class*="colorSuccess"]{
        background-color: ${props => props.theme.palette.colors.success} !important;
    }

    button[class*="colorSecondary"]{
        background-color: ${props => props.theme.palette.colors.secondary} !important;
    }

    .headerTime{
        color: ${props => props.theme.palette.layout.text} !important;

        button[class*="Secondary"]{
            background-color: ${props => props.theme.palette.colors.primary} !important;
        }
    }

    .timeLineStyle{
        color: ${props => props.theme.palette.layout.text} !important;
        div[class*="positionAlternate"] {
            border-radius: 4px;
            background-color: ${props => props.theme.palette.table.row};
            padding-left: 8px;
            padding-right: 8px;
        }
        div[class*="MuiTimelineSeparator"] {
            margin: 0 8px;
            .bg-danger{
                background-color: ${props => props.theme.palette.colors.error};
            }
            .bg-success{
                background-color: ${props => props.theme.palette.colors.success};
            }
            .bg-warning{
                background-color: ${props => props.theme.palette.colors.warning};
            }
            .bg-gray{
                background-color: ${props => props.theme.palette.colors.gray};
            }
        }
        div[class*="alignRight"] {
            background-color: transparent !important;
        }
    }
    /* ALERT HISTORY */
    .headerTitle {
        color: ${props => props.theme.palette.layout.text};
    }

    .item-btnFilter{
        .left{
            color: ${props => props.theme.palette.layout.text};
            .gray{
                color: ${props => props.theme.palette.colors.gray};
            }
            .danger{
                color: ${props => props.theme.palette.colors.error};
            }
            .success{
                color: ${props => props.theme.palette.colors.success};
            }
            .warning{
                color: ${props => props.theme.palette.colors.warning};
            }
        }
        &.active{
            .left{
                color: #FFFFFF !important;
            }
        }
        &:hover{
            background-color: ${props => props.theme.palette.card.hover};
        }
    }
    /* input datePicker and select*/
    div[class*="MuiTextField"]{
        label{
            color: ${props => props.theme.palette.layout.text} !important;
        }
        
        [class*="disabled"]{
            -webkit-text-fill-color: ${props => props.theme.palette.layout.text} !important;
        }
        .MuiSelect-select{
            color:  ${props => props.theme.palette.layout.text} !important;
            border-color: ${props => props.theme.palette.line.border} !important;
            
            &~ input{
                border-color: ${props => props.theme.palette.line.border} !important;
                
            }
        }
        
        [class*="MuiSelect-iconStandard"]{
            color: ${props => props.theme.palette.layout.text} !important;
        }
    }
    div[class*="adornedEnd"]{
        border-color: ${props => props.theme.palette.line.border} !important;
        color:  ${props => props.theme.palette.layout.text} !important;

        input{
            color:  ${props => props.theme.palette.layout.text} !important;
            &::placeholder{
                color:  ${props => props.theme.palette.layout.text} !important;
            }
        }
        > div[class*="positionEnd"] {
            button{
                color:  ${props => props.theme.palette.layout.text} !important;
            }
        }
    }
    /* instrument table */
    .instrument-component{
        .gray{
            color: ${props => props.theme.palette.colors.gray};
        }
        .danger{
            color: ${props => props.theme.palette.colors.error};
        }
        .success{
            color: ${props => props.theme.palette.colors.success};
        }
        .warning{
            color: ${props => props.theme.palette.colors.warning};
        }
    }
   
    .wrapper-chart{
        background-color: transparent !important;
        svg {
                g {
                    &.cursor-line-stroke {
                        stroke: ${props => props.theme.palette.layout.text} 
                    }
                    &.column-role {
                        fill: ${props => props.theme.palette.chart.bg} 
                    }
                    &.tooltip-role {
                        fill: ${props => props.theme.palette.chart.bg};
                        g{
                            fill: ${props => props.theme.palette.chart.bg}
                        }
                    }
                    &.tooltip-bot-role, .tooltip-right-role  {
                        g{
                            fill: ${props => props.theme.palette.chart.bg};
                        }
                    }
                    &[role="slider"] g {
                        fill: ${props => props.theme.palette.chart.slider} 
                    }

                    &[role="button"] g {
                        fill: ${props => props.theme.palette.chart.bgButton} 
                    }
                }
                
                text {
                    fill: ${props => props.theme.palette.chart.text};
                    font-family: ${props => props.theme.typography.primary}, sans-serif
                }
            }
    }
    .wrapper-table{
        > div {
            border-color: ${props => props.theme.palette.line.gray50};
            color: ${props => props.theme.palette.layout.text} !important;
            
            
            &:first-child{
                border: none !important;
            }
            
            .content-name {
                
            }
        }
    }
    .headerTime{

        .btn-group{
            button, .btn{
                background-color: transparent;
                border-color: ${props => props.theme.palette.line.border} !important;
                border-right: 0;
                color: ${props => props.theme.palette.layout.text} !important;
                font-family: ${props => props.theme.typography.primary}, sans-serif !important;
                
                svg{
                    color: ${props => props.theme.palette.layout.text} !important;
                }
                &:not(:first-of-type){
                    border-right-color: ${props => props.theme.palette.line.border} !important;
                }
                &:hover, &.active{
                    background:  ${props => props.theme.palette.card.hover} !important;
                }
            }
        }
    }

    /* WATCHLIST */
    .watchlistStyle{
        background-color: transparent !important;
        color:  ${props => props.theme.palette.layout.text};
        .header{
            .contentTabs{
                justify-content: space-between !important;
                gap: 10px;
                div[class*="MuiTabs"]{
                    gap: 7px;
                    >div[class*="MuiTabScrollButton"]{
                        svg{
                            color: ${props => props.theme.palette.layout.text} !important;
                        }
                    }
                    button{
                        background:  ${props => props.theme.palette.card.tab} !important;
                        ${FontSize[12]};
                        text-transform: uppercase !important;
                        border-radius: 2px;
                        
                        &:hover{
                            opacity: 0.9;
                        }
                        &[class*="selected"]{
                            color: #FFFFFF !important;
                            background-color: ${props => props.theme.palette.colors.primary} !important;
                        }
                    }
                    span[class*="indicator"]{
                        background-color:  ${props => props.theme.palette.colors.primary} !important;
                    }
                }
                button.addWatchlist{
                    display: none;
                }
            }
            .contentActions{
                .left{
                    button{
                        background-color: transparent !important;
                        border-radius: 2px !important;
                        color: ${props => props.theme.palette.layout.text} !important;
                        
                        &:hover{
                            background-color: ${props => props.theme.palette.card.hover} !important;
                        }
                        .text{
                            line-height: 1;
                        }
                    }
                }
                .right{
                    padding-right: 0px;
                }
                button{
                    background-color: ${props => props.theme.palette.colors.primary} !important;
                }
            }
        }
        div[role="tabpanel"]{
            .fullHeight{
                overflow: auto;
            }
        }
        .quoteCard{
            background-color: ${props => props.theme.palette.colors.gray} !important;
            ${FontSize[11]};
            
            &[color='EB5757'] {
                background-color: ${props => props.theme.palette.colors.down} !important;
            }
            &[color='C73C3C'] {
                background-color: ${props => props.theme.palette.colors.down2} !important;
            }
            &[color='A12C2C'] {
                background-color: ${props => props.theme.palette.colors.down3} !important;
            }
            &[color='1BC47D'] {
                background-color: ${props => props.theme.palette.colors.up} !important;
            }
            &[color='179962'] {
                background-color: ${props => props.theme.palette.colors.up2} !important;
            }
            &[color='0D7D4E'] {
                background-color: ${props => props.theme.palette.colors.up3} !important;
            }

            .name {
                ${FontSize[12]};
            }
            .price {
                font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                ${FontSize[24]};
                font-weight: 600;
            }
            .space-betwwen{
                label{
                    font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                    ${FontSize[12]};
                    font-weight: 400;
                    span{
                        font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                        font-weight: 400;
                        ${FontSize[10]};
                    }
                }
            }
        }
        .simple-chart{
            .headerChart{
                .contentName{
                    color: ${props => props.theme.palette.layout.text} !important;
                    
                }
                .contentData{
                    color: ${props => props.theme.palette.layout.text} !important;
                    
                    .left .contentPrices {
                        
                    }
                }
            }
            &[color=up] {
                .headerChart{
                    .contentName{
                        svg{
                            color: ${props => props.theme.palette.colors.up} !important;
                        }
                    }
                }
            }
            &[color=down] {
                .headerChart{
                    .contentName{
                        svg{
                            color: ${props => props.theme.palette.colors.down} !important;
                        }
                    }
                }
            }
            svg {
                g {
                    &.cursor-line-stroke {
                        stroke: ${props => props.theme.palette.layout.text} 
                    }
                    &.column-role {
                        fill: ${props => props.theme.palette.chart.bg} 
                    }
                    &.tooltip-role {
                        fill: ${props => props.theme.palette.chart.bg};
                        g{
                            fill: ${props => props.theme.palette.chart.bg}
                        }
                    }
                    &.tooltip-bot-role, .tooltip-right-role  {
                        g{
                            fill: ${props => props.theme.palette.chart.bg};
                        }
                    }
                    &[role="slider"] g {
                        fill: ${props => props.theme.palette.chart.slider} 
                    }

                    &[role="button"] g {
                        fill: ${props => props.theme.palette.chart.bgButton} 
                    }
                }
                
                text {
                    fill: ${props => props.theme.palette.chart.text};
                }
            }
            .tickerName{
                ${FontSize[14]};
            }
            .tickerNameLarge{
                ${FontSize[11]};
                margin-bottom: 1px;
            }
            .left{
                .price{
                    ${FontSize[22]}
                }
                .var{
                    ${FontSize[12]}
                }
                .percent{
                    ${FontSize[12]}
                }
                &.flex{
                    align-items: center !important;
                    .price{
                        ${FontSize[14]}
                    }
                    .var{
                        ${FontSize[14]}
                    }
                    .percent{
                        ${FontSize[14]}
                    }
                }
            }
        }
    }
    
    /* BARCHART */
    .bar-chart-style{
        max-height: 330px;
        svg {
            g {
                &.grid-role {
                    stroke: ${props => props.theme.palette.chart.lineGrid} 
                }
                &.ticker-name-role {
                    text{
                        fill: ${props => props.theme.palette.chart.text};
                        tspan{
                            font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                        }
                    }
                }
                &.value-chart-role {
                    text{
                        fill: ${props => props.theme.palette.chart.text};
                        tspan{
                            font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                        }
                    }
                }
                &.value-item-role {
                    text{
                        fill: ${props => props.theme.palette.chart.text};
                        tspan{
                            font-family: ${props => props.theme.typography.secondary}, sans-serif !important;
                        }
                    }
                }
            }
            
        }
    }
    .wrapper-no-data{
        color: ${props => props.theme.palette.layout.text} !important;
        .message{
            ${FontSize[10]};
            
        }
    }
`;

export { ContentTable };